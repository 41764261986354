import React from 'react';
import ipfs from '../img/ipfs.png'
import eth from '../img/eth-diamond.png'
import price from '../img/price.png'
import pfp from '../img/pfp.png'
import cc0 from '../img/GIF.gif'


function Info() {

  return (
    <div className="container pt-5" style={{backgroundColor:"#021024", paddingBottom:"5%"}}>
      <div className="row">

        <div className="col info-icon">
          <img src={pfp} style={{height:"50px"}} alt="Price"/><br/>
          <p className="pt-3">
            PFP Collection
          </p>
        </div>

        <div className="col info-icon">
          <img src={ipfs} style={{height:"50px"}} alt="IPFS"/><br/>
          <p className="pt-3">
            IPFS hosted
          </p>
        </div>

        <div className="col info-icon">
          <img src={cc0} style={{height:"50px"}} alt="CC0"/><br/>
          <p className="pt-3">
            CC0 derivative<br/>project
          </p>
        </div>

        <div className="col info-icon">
          <img src={eth} style={{height:"50px"}} alt="ETH"/><br/>
          <p className="pt-3">
            Built on ETH
          </p>
        </div>

      </div>

      <div className="row">
        <p className="mt-5">
          Smart Contract address: <a href="https://etherscan.io/address/0x0201b91cc438dd6b5d594dd5a058256ca0dc1816" target="_blank" rel="noopener noreferrer">0x0201b91cc438dd6b5d594dd5a058256ca0dc1816</a>
        </p>
      </div>
    </div>
  );
}

export default Info;