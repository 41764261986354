import React from 'react';
import front from '../img/front_intro.png'


function Intro() {

  return (
    <div className='container'>
      <div className="row d-flex align-items-center">
        <div className="col px-5 text-start">
          <h1 style={{fontSize:"40px"}} className="Playfair-title">
            <b>Spectres have arrived.<br/>Moonbirds have found their enemies on the blockchain</b>
          </h1>
          <p>
            Spectralbirds is a collection of 10000 PFPs that give you access to the Spectralbirds community with exclusive privileges.
            Owning a Spectralbirds give you the power to vote to decide the future of the project.<br/>
            Get your NFT and scary all the Moonbirds out there.
          </p>
        </div>
        <div className="col px-5">
          <img src={front} style={{width:"100%", height:"100%", minWidth:"300px"}} alt="SpectralBirds are here!"/>
        </div>
      </div>
    </div>
  )
}

export default Intro;