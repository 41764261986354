import './App.css';
import './smoke.scss';
import Intro from "./components/Intro";
import Mint from "./components/Mint";
import Phases from "./components/Phases";
import Title from "./components/Title";
import Info from "./components/Info";


function App() {

  return (
    <div className="App serif-noto background_app">

      <div className="container-fluid">
        <div className="row">
          <Title/>
        </div>
      </div>

      <div className="container">

        <div className="row" style={{marginTop:"5%"}}>
          <Intro/>
        </div>

        <div className="row" style={{marginTop:"12%"}} id="Mint">
          <Mint/>
        </div>

        <div className="row" style={{marginTop:"12%"}}>
          <Phases/>
        </div>

      </div>

      <div className="container-fluid">

        <div className="row" style={{marginTop:"15%"}}>
          <Info/>
        </div>

      </div>

    </div>
  );
}

export default App;
