import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import contract from '../contracts/NFT.json'


export const contractAddress = "0x0201b91Cc438Dd6b5D594dd5A058256cA0dc1816"
export const contractAbi = contract.abi
export const NFTprice = 0;

//const rpc_url = "https://cloudflare-eth.com/v1/mainnet"
//const rpc_url = "https://cloudflare-eth.com/v1/rinkeby"
//const rpc_url = "https://cloudflare-eth.com/v1/goerli
const rpc_url = "https://main-rpc.linkpool.io/"
//const rpc_url = "https://nodes.mewapi.io/rpc/eth"
//const rpc_url = "https://rinkeby-light.eth.linkpool.io/"
//const rpc_url = "https://goerli-light.eth.linkpool.io"

export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "b2b3a2eac0264870b15d098d2bcfbf33",
      //rpc: {1: rpc_url}
    }
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "Spectralbirds", // Required
      infuraId: "b2b3a2eac0264870b15d098d2bcfbf33",
      //rpc: rpc_url, // Optional if `infuraId` is provided; otherwise it's required
      chainId: 1, // Optional. It defaults to 1 if not provided
      darkMode: false // Optional. Use dark theme, defaults to false
    }
  },
};