import React, {useState} from 'react';
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import {providerOptions} from "../constant/web3";
import {contractAddress, contractAbi, NFTprice} from "../constant/web3";

function Mint() {
  const [showMint, setShow] = useState(true);
  const [showConnect, setConnect] = useState(true);
  const [account, setAccount] = useState(null);
  const [provider, setProvider] = useState(null);
  const [error, setError] = useState(null);
  const [successLink, setSuccessLink] = useState("");
  const [count, setCount] = useState(1);
  const [switchNetwork, setSwitchNetwork] = useState(false);


  const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions, // required
  });

  const mint = async () => {
    setError(null)
    setSuccessLink("")
    try{
      if (provider) {
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, contractAbi, signer);
        const cost = String(NFTprice*count)

        console.log("Initialize payment");
        let gas_limit = 200000*count
        setShow(false)
        let nftTxn = await nftContract.mintNFTs(count, {value: ethers.utils.parseEther(cost) , gasLimit:gas_limit});

        console.log("Minting... please wait");
        await nftTxn.wait();

        console.log(`Minted, see transaction: https://etherscan.io/tx/${nftTxn.hash}`);
        await provider.getTransactionReceipt(nftTxn.hash)
          .then(r => {
            let link = String(parseInt(r.logs[0].topics[3],16));
            console.log(link)
            setSuccessLink(link)
          })
      }
      else{
        console.log("Please connect a Wallet")
      }
      setShow(true)
    }catch (err) {
      console.log(err);
      setError("Error while minting, please retry and make sure you are allowed to mint");
      setShow(true)
    }
  }

  const connectButton = async () => {

    try {
      const instance = await web3Modal.connect();
      const provider_ = new ethers.providers.Web3Provider(instance);
      const signer = provider_.getSigner();
      const wallet_account = await signer.getAddress()
      console.log("Balance = "+await signer.getBalance())

      if (wallet_account) {
        console.log("Found an account! Address ", wallet_account);
        setAccount(wallet_account)
        setProvider(provider_)
        let chainId = (await provider_.getNetwork()).chainId
        if(chainId !== 1) {
          console.log("Change network")
          setSwitchNetwork(true)
          setError(null)
          setSuccessLink("")
        }else {
          setError(null)
          setSuccessLink("")
          setConnect(false)
        }
      } else {
        console.log("No account found!");
        setError("No authorized account found")
        setSuccessLink("")
      }
    }catch (err){
      console.log(err);
      setError("Error occurred while connecting wallet")
      setSuccessLink("")
    }
  }

  const minting = () => {
    return(
      <button className="btn btn-primary border border-dark border-2 py-2" type="button" disabled style={{paddingInline: "25%", fontSize: "20px", minWidth:"250px"}}>
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Minting...
      </button>
    )
  }

  const dropdown = () => {
    return(
      <div className="input-group mb-3 ms-1" style={{paddingInline: "25%", fontSize: "20px", minWidth:"200px"}}>
        <label className="input-group-text bg-secondary border-secondary" htmlFor="inputGroupSelect01" style={{fontSize: "20px"}}>Amount</label>
        <select className="form-select bg-secondary border-dark text-center" style={{fontSize: "20px"}} id="inputGroupSelect01" onChange={e => setCount(e.target.value)} defaultValue={String(count)}>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </div>
    )
  }

  const clearWallet = async () => {
    await web3Modal.clearCachedProvider()
    localStorage.clear()
    setConnect(true);
    setSwitchNetwork(false)
  }

  const changeButton = () => {

    let lenAcc = account.length

    return(
      <div className="container-fluid d-flex justify-content-center">
        <div className="row">
          <div className="col Ubuntu-font px-0">
            <p style={{fontSize:"12pt"}}><b>{`Address: ${account.substring(0,6)}...${account.substring(lenAcc-5,lenAcc-1)}`}</b></p>
          </div>
          <div className="col px-0">
            <button className="btn btn btn-warning" style={{paddingInline: "6%"}} onClick={() => clearWallet()}>
              Change Wallet
            </button>
          </div>
        </div>
      </div>
    )
  }


  const mintButtons = () => {
    return(
      <div>
        {showMint ?
          <div>
            {changeButton()}
            {dropdown()}
            <button onClick={() => {mint().then();}} className='btn btn-info border border-dark border-2 py-2' style={{paddingInline: "25%", fontSize: "20px", minWidth:"250px"}}>
              Mint
            </button>
          </div>:
          <div>
            {minting()}
          </div>
        }
      </div>
    )
  }

  const ethMinting = () => {
    return(
        showConnect ?
          <div>
            <button onClick={() => {connectButton().then();}} className='btn btn-info border border-dark border-2 py-2 connect-button' style={{paddingInline: "25%", fontSize: "20px", minWidth:"250px"}}>
              Connect to Mint
            </button>
            <br/>
            Make sure your wallet is <span style={{color:"yellow"}}> connected</span> to the <span style={{color:"yellow"}}> ethereum </span>network
          </div>
          :
          <div>
            {mintButtons()}
          </div>
      )
  }

  const switchNet = () => {
    return(
      <div>
        <p style={{color:"yellow"}}>
          Switch to ethereum blockchain in your wallet, then press on the button below
        </p>
        <button className="btn btn btn-warning" style={{paddingInline: "6%"}} onClick={() => clearWallet()}>
          Network changed
        </button>
      </div>
    )
  }


    return (
    <div className='container'>
      <div className="row d-flex align-items-center">
        <div className="col px-5">
          {switchNetwork ?
            switchNet():
            ethMinting()
          }

          {successLink !== "" ?
            <div className="alert alert-success mt-3" role="alert">
              NFT Minted, see it on <a href={`https://opensea.io/assets/ethereum/${contractAddress}/${successLink}`} target="_blank" rel="noopener noreferrer">OpenSea</a>
            </div>
            : null}

          {error!=null ?
            <div className="alert alert-danger rounded mt-3" role="alert">
              {error}
            </div>
            : null}
        </div>

        <div className="col px-5 text-start mt-5 mt-md-0">
          <h1 style={{fontSize:"40px"}} className="Playfair-title">
            <b>Phase 0: Spirit</b>
          </h1>
          <p>
            During the Spirit phase the minting will be free and open to anyone.
            In this phase 1000 NFTs will be available to mint and each address will be allowed to mint a maximum of 5 Spectralbirds.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Mint;