import React from 'react';
import bird from '../img/bird.gif'


function Phases() {

  return (
    <div className='container'>
      <div className="row d-flex align-items-center">
        <div className="col px-5 text-start">
          <h1 style={{fontSize:"40px"}} className="Playfair-title">
            <b>Phase 1: Spirit</b>
          </h1>
          <p>
            During the Spectre phase the minting will be open to anyone.
            In this phase other NFTs will be available to mint and each address will be allowed to mint a maximum of 5 Spectralbirds.
          </p>

          <h1 style={{fontSize:"40px", marginTop:"10%"}} className="Playfair-title">
            <b>Phase 2: Ghost</b>
          </h1>
          <p>
            During the Ghost phase for every two Spectralbirds owned, one NFT can be freely minted (e.g., if you own 2 Spectralbirds you can require 1 Spectralbird, if you own 7 you can require up to 3 Spectralbirds, etc.).
          </p>

          <h1 style={{fontSize:"40px", marginTop:"10%"}} className="Playfair-title">
            <b>Phase 3: Phantom</b>
          </h1>
          <p>
            During the Phantom phase the remaining Spectralbirds will be released, the minting will be open to anyone.<br/>
            We reserved 500 NFTs that, during these phase, will be gifted to some random owners. Reserved NFTs will also be used for special and charity events.
          </p>
        </div>
        <div className="col mt-sm-5 mt-md-0 pb-4 floating-img">
          <img src={bird} alt="Error2" style={{width:"100%", height:"100%", minWidth:"300px"}}/>
        </div>
      </div>
    </div>
  )
}

export default Phases;

//During these phase the team will mint some of the reserved NFTs that will be gifted to some owners.