import React from 'react';
import discord_logo from "../img/Discord-Logo.svg";
import instagram_logo from "../img/instagram_logo.png";
import twitter_logo from "../img/twitter_logo.svg";


function Title() {

  return (
    <div className='container px-5' style={{paddingTop:"5%"}}>
      <div>
        <h1 className="Playfair-title floating d-flex justify-content-center">
          <span style={{fontSize:"160%"}} className="ghost-effect">S</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">p</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">e</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">c</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">t</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">r</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">a</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">l</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">b</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">i</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">r</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">d</span>
          <span style={{fontSize:"160%"}} className="ghost-effect">s</span>
        </h1>

        <div className="flex-row mt-5 row-cols-lg-1">
          <a href="https://discord.gg/m2zex36tpP" target="_blank" rel="noopener noreferrer" style={{paddingRight:"10%"}}>
            <img className="social-logo" src={discord_logo} alt="Discord"/>
          </a>
          <a href="https://www.instagram.com/spectralbirds/" target="_blank" rel="noopener noreferrer">
            <img className="social-logo" src={instagram_logo} alt="Instagram"/>
          </a>
          <a href="https://twitter.com/Spectralbirds_" target="_blank" rel="noopener noreferrer" style={{paddingLeft:"10%"}}>
            <img className="social-logo" src={twitter_logo} alt="Twitter"/>
          </a>
        </div>
      </div>
      <div className="container pt-5 d-flex justify-content-center Playfair-title">
        <button onClick={() => {window.location="#Mint"}} className="flash-text" style={{fontSize:"40px", backgroundColor:"rgba(0, 0, 0, 0)", borderColor:"rgba(0, 0, 0, 0)"}}>Free Mint is open</button>
      </div>

    </div>
  )
}

export default Title;